<template>
  <div>
    <headers />
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <a
          draggable="false"
          target="_self"
          class="main-grid--in-grid transition-opacity duration-500 hover:opacity-80 focus:opacity-80"
          href="/growth/insights"
          ><div class="relative transition-opacity duration-300 overflow-hidden" style="opacity: 1; transform: none">
            <div
              data-testid="tout-video-bg"
              class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
            >
              <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
                <div autoplay="" style="width: 100%; height: 100%">
                  <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b1.png')" alt="" />
                </div>
              </div>
            </div>
            <div
              class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
            >
              <div
                class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
              >
                <div class="mt-[30px] md:my-0" style="opacity: 1; transform: none">
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">
                    2022 Topline <br class="hidden md:inline" />Growth and Efficiency
                  </h1>
                  <p class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-600 mt-2.5 max-w-[500px]">
                    Read the Report
                  </p>
                </div>
              </div>
            </div>
          </div></a
        >
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Global Expansion for ZEN CAPITAL Growth:<br class="hidden md:inline" />
                            Meet Our European Leadership
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            A conversation between Matt Jacobson and Seth Pierrepont
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Security and Compliance – Moving at the Speed of Drata
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            ZEN CAPITAL Growth deepens its partnership with Drata, a prominent security and compliance
                            automation platform, by leading the company's $200M Series C.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b4.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The Essential Tech Stack
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The tools and platforms powering modern-day software organizations
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b5.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            A Bulwark in Volatile Times:<br class="hidden md:inline" />
                            More Efficient Engineering Teams
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Insights from the 2022 ZEN CAPITAL Growth Engineering Report and Private Engineering Summit
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b6.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The ZEN CAPITAL Growth Enterprise Five
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Key performance indicators of Enterprise SaaS companies
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b7.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Frank Slootman on Leadership and the War Against Mediocrity
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Snowflake’s CEO shares his keys to accelerating performance.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b8.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            IPO on the Horizon? Find your finance head to lead the charge.
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Four key considerations for hiring your next CFO.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b9.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Protecting Data for an Era of Zero Trust
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            With data owners today having a better sense than ever of how theirs is being used, Virtru
                            defines zero trust data access.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b10.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            1Password
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Defining the future of human-centric security.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b11.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            CaptivateIQ
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Meeting the engine for modern sales compensation management.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b12.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The Future of Professional Development:<br class="hidden md:inline" />
                            Part 1
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Redefining professional development in a distributed world.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-8 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b13.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Looking Back and Moving Forward
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Insights from our new series on the future of work.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b14.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Panther’s Agility and Speed
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Momentum and scalability for modern security teams.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-5 col-start-20 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/updates/b15.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Motorway’s New Vision for Used Cars
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Building the digital exchange for used cars.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
